<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">{{ $t("Search") }}</h3>
            </b-card-header>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select
                        class="form-control"
                        v-model="cur_LocationID"
                      >
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('From Date') + '...'"
                        v-model="cur_start_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('To Date') + '...'"
                        v-model="cur_end_dt"
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <b-row class="justify-content-lg-center pt-4">
                      <b-col class="text-center">
                        <b-button variant="primary" @click="form_search">{{
                          $t("Search")
                        }}</b-button>
                      </b-col>
                      <b-col class="text-center">
                        <xlsx-workbook>
                          <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                          />
                          <xlsx-download :filename="getReportName('DiscountByOrder', 'xlsx')">
                            <b-button variant="primary">XLSX</b-button>
                          </xlsx-download>
                        </xlsx-workbook>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <p class="p-3" v-if="start_tm">
            <b-row>
              <b-col cols="12" class="font-weight-bold">
                <span v-if="LocationID">
                {{ getLocationName(LocationID) }} :
                </span>
                {{ start_tm }} to 
                {{ end_tm }}
              </b-col>
            </b-row>
            <b-row class="mt-4 font-weight-bold border-bottom">
              <b-col cols="4">
                {{ $t('Name') }}
              </b-col>
              <b-col cols="2" class="text-right">
                {{ $t('Subtotal') }}
              </b-col>
              <b-col cols="2" class="text-right">
                {{ $t('Discount') }}
              </b-col>
            </b-row>
            <b-row  v-for="(rc, idx) in listdata" :key="idx">
              <b-col cols="4" class="border">
                {{ rc.DiscountName1 }}
              </b-col>
              <b-col cols="2" class="text-right border">
                {{ parseFloat(rc.SubTotal).toFixed(2) }}
              </b-col>
              <b-col cols="2" class="text-right border">
                {{ parseFloat(rc.DiscountAmount).toFixed(2) }}
              </b-col>
            </b-row>
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-4"> <b-col> </b-col> </b-row
      ><!--  -->
    </b-container>
    <vue-element-loading
      :active="showLoading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    VueElementLoading,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
        maxDate: "today",
      },
      showLoading: false,

      token: "",
      user_id: 0,
      storelist: null,
      listdata: null,

      cur_LocationID: 0,
      cur_start_dt: null,
      cur_end_dt: null,

      LocationID: "",
      start_tm: "",
      end_tm: "",
      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
    };
  },
  computed: {
    is_english: function () {
      switch (this.$i18n.locale) {
        case 'en':
          return true;
        case 'cn':
          return false;
        case 'cn-t':
          return false;
        default:
          return true;

      }
    },
  },
  methods: {
    async get_store_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store/report_tag_search",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.cur_LocationID = "";
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    fillup_sheet() {
      // Init 2 dimensional array
      var dataarr = [];
      for (var i = 0; i < 10; i++) {
        dataarr[i] = [];
      }

      try {
        // Date line
        var y = 0;
        if (this.LocationID) {
          dataarr[y++][0] = this.LocationID + " : ";
        }
        dataarr[y++][0] += this.cur_start_dt + " To " + this.cur_end_dt;
        if (this.listdata) {
          dataarr[y][0] += this.$t('Name');
          dataarr[y][1] += this.$t('Subtotal');
          dataarr[y][2] += this.$t('Discount');
          y++;
          for (var key in this.listdata) {
            if (this.listdata.hasOwnProperty(key)) {
              dataarr[y][0] = this.listdata[key].DiscountName1;
              dataarr[y][1] = this.listdata[key].SubTotal;
              dataarr[y][2] = this.listdata[key].DiscountAmount;
              y++;
            }
          }
        }
      } catch (err) {
        console.log("fillup_sheet err",err);
      }
      // console.log("fillup_sheet",dataarr);
      var sheetname = "OrderDiscount";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    remove_dashColons_replace_space_with_underscore(myString) {
      if (myString == undefined) {
        return '';
      }
      var newString = myString.replace(/[-:]/g, "");
      newString = newString.replace(" ", "_");
      return newString;
    },
    getReportName(reportName , reportType) {
      let start = this.remove_dashColons_replace_space_with_underscore(this.cur_start_dt);
      let end = this.remove_dashColons_replace_space_with_underscore(this.cur_end_dt);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    getLocationName(Location) {
      for (var i=0; i<this.storelist.length; i++) {
        if (this.storelist[i].LocationID == Location) {
          return this.storelist[i].LocationName
        }
      }
      return "";
    },
    async get_list_data() {
      this.showLoading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Discount_by_order",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showLoading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.listdata = rt.data.listdata;
              that.start_tm = rt.data.start_time;
              that.end_tm = rt.data.end_time;
              that.LocationID = rt.data.LocationID;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            // console.log("Pos_sales_report_by_product ====>>> ", JSON.parse(JSON.stringify(that.listdata)));
            that.fillup_sheet();
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showLoading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    async init_data() {
      this.cur_start_dt = this.get_today();
      this.cur_end_dt = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_store_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
